// extracted by mini-css-extract-plugin
export var appAddInfo = "Apps-module--appAddInfo--zi-kV";
export var appContainer = "Apps-module--appContainer--zQRs3";
export var appHeader = "Apps-module--appHeader--krOpQ";
export var appImage = "Apps-module--appImage--VaOKZ";
export var appInfo = "Apps-module--appInfo--aK5sd";
export var btn = "Apps-module--btn--DDfSR";
export var desktopContainer = "Apps-module--desktopContainer--uXFnV";
export var desktopSection = "Apps-module--desktopSection--dDEx7";
export var downloadInfo = "Apps-module--downloadInfo--IkPjt";
export var headerImg = "Apps-module--headerImg--eZLEs";
export var hideMobile = "Apps-module--hideMobile--KRN1Y";
export var item = "Apps-module--item--TDQKD";
export var mobileContainer = "Apps-module--mobileContainer--cumJQ";
export var mobileImg = "Apps-module--mobileImg--nVlGx";
export var mobileSection = "Apps-module--mobileSection--XyCOO";
export var phones = "Apps-module--phones--KpLbb";
export var positionFlex = "Apps-module--positionFlex--4YoGO";
export var showMobile = "Apps-module--showMobile--3Luzh";
export var storeBtn = "Apps-module--storeBtn--aDfRJ";
export var stores = "Apps-module--stores--xXm4A";